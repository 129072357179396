<template>
  <b-sidebar
      width="800px"
      id="request-leave-side-bar"
      :visible="isRequestLeaveSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-request-leave-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay
          :show="formLoading"
          rounded="sm"
      >

        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4 class="mb-0">
            Request Leave
          </h4>

          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="resetData(); hide"
          />

        </div>
        <div class="p-2">
          <validation-observer ref="requestLeaveForm">
            <b-form @submit.prevent>

              <b-row class="mt-2">
                <b-col cols="12" md="6">
                  <label>Start Date</label>
                  <validation-provider
                      #default="{ errors }"
                      name="Start Date"
                      rules="required"
                  >
                    <b-form-group>
                      <b-input-group class="input-group-merge">
                        <b-form-datepicker
                            id="start_date"
                            :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
                            v-model="selectedDayOffStartDate"
                            start-weekday="1"
                            :min="min"
                            class="form-control"
                            placeholder="Select"
                            locale="en-UK"
                            trim/>
                      </b-input-group>
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small></validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                  <label>End Date</label>
                  <validation-provider
                      #default="{ errors }"
                      name="End Date"
                      rules="required"
                  >

                    <b-form-group>
                      <b-input-group class="input-group-merge">
                        <b-form-datepicker
                            id="end_date"
                            :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
                            v-model="selectedDayOffEndDate"
                            :disabled="disableEndDate"
                            start-weekday="1"
                            :min="minDate"
                            class="form-control"
                            placeholder="Select"
                            locale="en-UK"
                            trim/>
                      </b-input-group>
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small></validation-provider>
                </b-col>
              </b-row>
              <!-- <b-form
                  ref="form"
                  :style="{height: trHeight}"
                  class="repeater-form"
                  @submit.prevent="repeateAgain"
              >
                <b-row>
                  <b-col>
                    <b-row
                        v-for="(item, index) in leaveRequests"
                        :id="item.id"
                        :key="item.id"
                        ref="row"
                        class="mt-1"
                    >
                      <b-col cols="12" md="3">
                        <label>Date</label>
                        <validation-provider
                            ref="date"
                            #default="{ errors }"
                            :bails="false"
                            :name="'Date'+index"
                            rules="required"
                        >
                          <b-form-group>

                            <b-form-datepicker
                                :date-format-options="{ year: '2-digit', month: 'numeric', day: 'numeric' }"
                                v-model="leaveRequests[index].selectedStartDate"
                                start-weekday="1"
                                class=""
                                placeholder="Select"
                                locale="en-UK"
                                :min="minDate"
                                :max="maxDate"
                                trim/>
                          </b-form-group>
                          <small class="text-danger" v-if="errors[0]">The Date field is required</small>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" md="3">
                        <b-form-group>
                          <label>Start Time</label>
                          <validation-provider
                              ref="startTime"
                              #default="{ errors }"
                              :bails="false"
                              :name="'Start Time'+index"
                              rules="required"
                          >
                            <b-form-group>
                              <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                                  <feather-icon icon="ClockIcon"/>
                                </b-input-group-prepend>
                                <flat-pickr
                                    v-model="leaveRequests[index].selectedStartTime"
                                    :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', }"
                                    class="form-control"
                                    placeholder="Select"
                                />
                              </b-input-group>
                            </b-form-group>
                            <small class="text-danger" v-if="errors[0]">The Start Time field is required</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="3">
                        <label>End Time</label>
                        <validation-provider
                            ref="endTime"
                            #default="{ errors }"
                            :bails="false"
                            :name="'End Time'+index"
                            rules="required"
                        >
                          <b-form-group>
                            <b-input-group class="input-group-merge">
                              <b-input-group-prepend is-text>
                                <feather-icon icon="ClockIcon"/>
                              </b-input-group-prepend>
                              <flat-pickr
                                  v-model="leaveRequests[index].selectedEndTime"
                                  :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', }"
                                  class="form-control"
                                  placeholder="Select"
                              />
                            </b-input-group>
                          </b-form-group>
                          <small class="text-danger" v-if="errors[0]">The End Time field is required</small>
                        </validation-provider>
                      </b-col>
                      <div style="margin-top:30px" cols="12" md="2">
                        <div class="set-mb">
                          <p>{{ hoursCalculate(leaveRequests[index]) }} hrs</p>
                        </div>
                      </div>
                      <b-col cols="12" class="" style="margin-top:25px" md="2">
                        <b-button v-if="leaveRequests.length == index + 1"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  class="btn-icon rounded-circle"
                                  size="20"
                                  style="padding:5px"
                                  variant="outline-white"
                                  @click="repeateAgain"
                        >
                          <feather-icon icon="PlusCircleIcon" size="17"/>
                        </b-button>
                        <b-button
                            v-if="leaveRequests.length > 1"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            class="btn-icon rounded-circle"
                            size="20"
                            style="padding:5px"
                            variant="outline-white"
                            @click="removeItem(index)"
                        >
                          <feather-icon icon="MinusCircleIcon" size="17"/>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form> -->

              <b-form
                ref="form"
                :style="{height: trHeight}"
                class="repeater-form"
                @submit.prevent="repeateAgain"
            >
              <b-row v-for="(item, index) in leaveRequests"
                      :id="item.id"
                      :key="item.id"
                      ref="row">

            <b-col cols="12" md="3">
              <label>Date</label>
              <validation-provider
                  ref="date"
                  #default="{ errors }"
                  :bails="false"
                  :name="'Date'+index"
                  rules="required"
              >
              <b-form-group>
                <b-input-group class="input-group-merge">
                   <b-form-datepicker
                        :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
                        v-model="leaveRequests[index].selectedStartDate"
                        placeholder="Select"
                        start-weekday="1"
                        :min="minDate"
                        :max="maxDate"
                        class="form-control"
                        locale='en-UK'
                        trim/>
                </b-input-group>
              </b-form-group>
                <small  class="text-danger" v-if="errors[0]">The Date field is required</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="9">
              <b-row>
                <b-col cols="12" md="3">
              <label>Start Time</label>
              <validation-provider
                  ref="startTime"
                  #default="{ errors }"
                  :bails="false"
                  :name="'Start Time'+index"
                  rules="required"
              >
              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ClockIcon"/>
                  </b-input-group-prepend>
                  <flat-pickr
                      v-model="leaveRequests[index].selectedStartTime"
                      :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', }"
                      class="form-control"
                      placeholder="Select"
                  />
                </b-input-group>
              </b-form-group>
              <div v-if="isEndDate">
                <small  class="text-danger" v-if="errors[0]">The Start Time field is required</small>
              </div>
              </validation-provider>
            </b-col>
                <b-col cols="12" md="3">
              <label>End Time</label>
              <validation-provider
                  ref="endTime"
                  #default="{ errors }"
                  :bails="false"
                  :name="'End Time'+index"
                  rules="required"
              >
              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ClockIcon"/>
                  </b-input-group-prepend>
                  <flat-pickr
                      v-model="leaveRequests[index].selectedEndTime"
                      :config="{ enableTime: true, noCalendar: true,  dateFormat: 'H:i', }"
                      class="form-control"
                      placeholder="Select"
                  />
                </b-input-group>
              </b-form-group>
              <div v-if="isEndDate">
                <small  class="text-danger" v-if="errors[0]">The End Time field is required</small>
              </div>
              </validation-provider>
            </b-col>
                 <b-col class="" style="margin-top:32px" cols="12" md="4">
                <span class="set-mb">
                    <p><span style="color:#1B9AAA" v-if="hoursCalculate(leaveRequests[index])>0">{{hoursCalculate(leaveRequests[index])}} </span>
                      <span  style="color:#1B9AAA" v-if="hoursCalculate(leaveRequests[index])>0"> hrs</span>
                      <span
                        v-if="leaveRequests[index].break_minutes>0">{{
                        ` (${leaveRequests[index].break_minutes} min break)`
                      }}</span>
                    </p>
                </span>
             </b-col>
              <b-col class="" style="margin-top:30px" cols="12" md="2">   
              <b-button v-if="leaveRequests.length == index + 1"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="btn-icon rounded-circle"
                  size="20"
                  style="padding:5px"
                  variant="outline-white"
                  @click="repeateAgain"
              >
                <feather-icon icon="PlusCircleIcon" size="17"/>
              </b-button>
              <b-button
                  v-if="leaveRequests.length > 1"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="btn-icon rounded-circle"
                  size="20"
                  style="padding:5px"
                  variant="outline-white"
                  @click="removeItem(index)"
              >
                <feather-icon icon="MinusCircleIcon" size="17"/>
              </b-button>
            </b-col>
              </b-row>
            </b-col>
          </b-row>
            </b-form>
              <b-row>
                <b-col class="mt-1" cols="12">
                  <label>Reason</label>
                  <validation-provider
                      #default="{ errors }"
                      name="Reason"
                      rules="required"
                  >
                    <b-form-group>
                      <v-select
                          v-model="leaveReason"
                          :options="leaveReasonOptions"
                          :reduce="leaveReason => leaveReason.id"
                          label="name"
                          placeholder="Select"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row v-if="showSicknesInput">
                <b-col class="mt-1" cols="12">
                  <label>Sickness Type</label>
                  <validation-provider
                      #default="{ errors }"
                      name="Reason"
                      rules="required"
                  >
                    <b-form-group>
                      <v-select
                          v-model="sicknessType"
                          :options="sicknessTypeOptions"
                          :reduce="sicknessType => sicknessType.id"
                          label="name"
                          placeholder="Select"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="d-flex" v-if="trackLeaveOnDays" :hidden="saveDisable()">
                <div>
                  <b-col class="mt-1">
                    <h4> <span v-if="totalRequestedTime>0" style="color:#1B9AAA">{{
                          parseFloat(totalRequestedTime / practiceWorkingHours).toFixed(1)
                        }}</span>
                      <span v-else
                      style="color:#1B9AAA"> 0.0
                    </span> days of annual leave</h4>
                  </b-col>
                </div>
                <div>
                  <b-col class="mt-1">
                    <h4><span v-if="totalRequestedTime>0" v-bind:class="classBind ? 'minus-value' : 'plus-value'">{{
                          parseFloat((allocatedLeaveDays - usedLeaveDays) - (totalRequestedTime / practiceWorkingHours)).toFixed(1)
                        }}</span>
                      <span v-else v-bind:class="classBind ? 'minus-value' : 'plus-value'">{{
                          parseFloat((allocatedLeaveDays - usedLeaveDays) - (totalRequestedTime)).toFixed(1)
                        }}</span>
                      days remaining</h4>
                  </b-col>
                </div>
              </b-row>
              <b-row v-else :hidden="saveDisable()">
                <div>
                  <b-col class="mt-1">
                    <h4><span v-if="parseFloat(totalRequestedTime) > 0"
                    style="color:#1B9AAA">{{ parseFloat(totalRequestedTime).toFixed(1) }}</span>
                    <span v-else
                      style="color:#1B9AAA"> 0.0
                    </span> hours of annual leave</h4>
                  </b-col>
                </div>
                <div>
                  <b-col class="mt-1">
                    <h4><span v-if="parseFloat((allocatedLeaveHours - usedLeaveHours) - totalRequestedTime) >= 0"
                        style="color:#1B9AAA">{{
                        parseFloat((allocatedLeaveHours - usedLeaveHours) - totalRequestedTime).toFixed(1)
                      }}</span>
                      <span v-else
                        style="color:#cc0000">{{
                        parseFloat((allocatedLeaveHours - usedLeaveHours) - totalRequestedTime).toFixed(1)
                      }}</span>
                      <!-- {{classBind}} hours remaining -->
                      hours remaining</h4>
                  </b-col>
                </div>
              </b-row>
              <div class="d-flex mt-3">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    :disabled="saveDisable() || parseFloat(totalRequestedTime) < 0"
                    @click="requestLeave()"
                >
                  Save
                </b-button>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="text-primary ml-1 shadow"
                    type="button"
                    variant="white"
                    @click="resetData(); hide"
                >
                  Cancel
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </b-overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {

  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BInputGroupPrepend,
  BCol,
  BTable,
  BCard,
  BAvatar,
  BLink,
  BBadge,
  BCardBody,
  BCardHeader,
  BImg,
  BMedia,
  BFormSelect,
  BPagination, BFormRadio, BFormRadioGroup,
  BInputGroupAppend, BDropdown, BDropdownItem, BListGroup, BListGroupItem, VBTooltip, BOverlay, BFormTextarea


} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import MomentMixin from '@/mixins/MomentMixin'
import moment from 'moment'
import DateDiff from 'date-diff'
import {required} from '@validations'
import leaveManagementSalariedStaff from '@/apis/modules/leave_managment_salaried_staff'

export default {
  name: 'requestLeave',

  components: {
    flatPickr,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BRow,
    BCol,
    BInputGroupPrepend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BCard,
    BAvatar,
    BLink,
    BBadge,
    BCardBody,
    BCardHeader,
    BImg,
    BMedia,
    BTable,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    BOverlay,
    VBTooltip,
    BFormTextarea,
    BFormRadio,
    BFormRadioGroup
  },
  mixins: [MomentMixin],

  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  model: {
    prop: 'isRequestLeaveSidebarActive',
    event: 'update:is-request-leave-sidebar-active'
  },
  props: {
    isRequestLeaveSidebarActive: {
      type: Boolean,
      required: true
    }

  },
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth())
    minDate.setDate(minDate.getDate())
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth())
    maxDate.setDate(maxDate.getDate())
    return {
      isEndDate: false,
      min: minDate,
      max: maxDate,
      trHeight: null,
      required,
      form: {
        selectedReason: ''
      },
      test: '',
      userDetails: '',
      sicknessTypeOptions: [],
      showSicknesInput: false,
      formLoading: false,
      sicknessType: '',
      leaveReasonOptions: [],
      selectedStartDate: '',
      selectedDayOffStartDate: '',
      practiceWorkingHours: 0,
      selectedDayOffEndDate: '',
      selectedStartTime: '',
      selectedEndTime: '',
      selectedLeaveType: 'timeOff',
      Reason: '',
      leaveReason: '',
      selected: [],
      selectedCheck: true,
      totalRequestedTime: 0,

      perfectScrollbarSettings: {
        maxScrollbarLength: 100
      },
      selectedOption: {
        title: 'All',
        value: 1
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      fields: [
        {
          key: 'name',
          label: ' NAME'
        },
        {
          key: 'type',
          label: 'Type'
        }
      ],
      items: [],
      isActive: true,
      leaveRequests: [{
        id: 1,
        selectedStartDate: '',
        selectedStartTime: '',
        selectedEndTime: '',
        break_minutes: 0
      }],
      nextTodoId: 1,
      minDate: '',
      maxDate: '',
      disableEndDate: true,
      disableStartDate: true,
      usedLeaveDays: 0,
      allocatedLeaveDays: 0,
      allocatedLeaveHours: 0,
      usedLeaveHours: 0,
      trackLeaveOnDays: ''
    }
  },
  methods: {
    hide () {
      this.$emit('requestLeave')
    },
    repeateAgain () {
      this.leaveRequests.push({
        id: this.nextTodoId += 1
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem (index) {
      this.leaveRequests.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight () {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },

    resetData () {
      this.isRequestLeaveSidebarActive = false
      this.selectedLeaveType = 'timeOff',
          this.selectedStartDate = '',
          this.selectedStartTime = '',
          this.selectedEndTime = '',
          this.leaveReason = '',
          this.selectedDayOffStartDate = '',
          this.selectedDayOffEndDate = '',
          this.form.selectedReason = '',
          this.leaveRequests = [{
            id: 1,
            selectedStartDate: '',
            selectedStartTime: '',
            selectedEndTime: ''
          }]
      this.$refs.requestLeaveForm.reset()
      this.isEndDate = true;
    },
    async getWorkingHours (id, start, end) {
      try {
        this.formLoading = true
        const Response = await leaveManagementSalariedStaff.getUserWorkingHours(id)
        let mapper = Response.data.data.map((x) => ({
          start: x.times[0].start,
          end: x.times[0].end,
          break_minutes: x.times[0].break_minutes ? x.times[0].break_minutes : 0,
          day_number: x.day_number
        }))
        let dataMapper = []
        let dataSetter = []

        for (let i of mapper) {
          let start = new Date(i.start)
          let startHours = start.getHours()
          let startMinutes = start.getMinutes()
          let startSeconds = start.getSeconds()
          let startTime = startHours + ':' + startMinutes + ':' + startSeconds
          let end = new Date(i.end)
          let endHours = end.getHours()
          let endMinute = end.getMinutes()
          let endSeconds = end.getSeconds()
          let endTime = endHours + ':' + endMinute + ':' + endSeconds
          let day_num = i.day_number
          let break_minutes = i.break_minutes
          dataMapper.push({
            day_num: day_num,
            start: startTime,
            end: endTime,
            break_minutes
          })
        }

        var loopstart = new Date(start)
        var loopEnd = new Date(end)

        while (loopstart <= loopEnd) {

          let dayNumber = loopstart.getDay()
          let date = this.momentFormat(loopstart, 'YYYY-MM-DD')
          for (let j of dataMapper) {
            if (j.day_num == dayNumber) {
              let start = moment(date + ` ` + j.start).format('YYYY-MM-DD HH:mm:ss')
              let end = moment(date + ` ` + j.end).format('YYYY-MM-DD HH:mm:ss')
              let break_minutes = j.break_minutes
              dataSetter.push({
                start: start,
                end: end,
                break_minutes
              })
            }
          }
          var newDate = loopstart.setDate(loopstart.getDate() + 1)
          loopstart = new Date(newDate)
        }

        let newArray = dataSetter.map((x) => ({
          selectedStartTime: this.momentFormat(x.start, 'HH:mm'),
          selectedEndTime: this.momentFormat(x.end, 'HH:mm'),
          selectedStartDate: this.momentFormat(x.start, 'YYYY-MM-DD'),
          break_minutes: x.break_minutes
        }))
        if (newArray.length > 0) {
          this.leaveRequests = newArray
        } else {
          this.leaveRequests = [{
            id: 1,
            selectedStartDate: '',
            selectedStartTime: '',
            selectedEndTime: '',
            break_minutes: 0
          }]
        }
        this.formLoading = false
      } catch (error) {
        this.formLoading = false
        this.convertAndNotifyError(error)
      }
    },
    async getUserSummaryDetails (id) {
      try {
        const Response = await leaveManagementSalariedStaff.userSummary(id)
        let leave_takable_count = Response.data.data.users[0].leave_takable_count
        let working_hours_per_day = Response.data.data.working_hours_per_day
        let user_total_leave_per_year = Response.data.data.user_total_leave_per_year

        let total_work_hours = user_total_leave_per_year * working_hours_per_day
        let remaing = total_work_hours - leave_takable_count
        let used_days = remaing / working_hours_per_day
        this.usedLeaveDays = used_days
        this.allocatedLeaveDays = Response.data.data.users[0].number_of_leaves_per_year
        this.trackLeaveOnDays = !!Response.data.data.practice.track_leave_on_days
        this.allocatedLeaveHours = user_total_leave_per_year * working_hours_per_day
        this.usedLeaveHours = used_days * working_hours_per_day
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async requestLeave () {
      let response = []
      if(parseFloat((this.allocatedLeaveHours - this.usedLeaveHours) - this.totalRequestedTime) < 0){
        this.showErrorMessage('Please contact the Admin')
      }
      else{
      if (await this.$refs.requestLeaveForm.validate()) {
        try {
          this.formLoading = true
          let leaves = []
          let payload = {}
          leaves = this.leaveRequests.map((x) => ({
                start: moment(x.selectedStartDate + ` ` + x.selectedStartTime).utc().format('YYYY-MM-DD HH:mm:ss'),
                end: moment(x.selectedStartDate + ` ` + x.selectedEndTime).utc().format('YYYY-MM-DD HH:mm:ss'),
                break_minutes: x.break_minutes,
                is_day: false
              })
          )

          payload.leaveItems = leaves
          payload.start = this.leaveRequests[0].selectedStartDate + ` ` + this.leaveRequests[0].selectedStartTime
          payload.leave_reason = this.leaveReason
          payload.sickness_type = this.sicknessType
          payload.is_day = false

          response = await leaveManagementSalariedStaff.requestLeave(payload)
          console.log(response)

          if (response.data.success) {
            this.showSuccessMessage('Leave request was sent!')
            this.resetData()
            this.$emit('requestDataRefresh')
            this.$emit('requestLeave')
          } else {
            if (response.data.conflict) {
              this.showLeaveConflictErrorMessage(response)
            }
          }
          this.formLoading = false
        } catch (error) {
          this.formLoading = false
          this.convertAndNotifyError(error)
        }
      }
      }
      this.isEndDate = false;
    },

    annualLeaveHours () {
      try {
        let totalCalc = 0
        for (let leave of this.leaveRequests) {
          if (leave.selectedStartTime && leave.selectedEndTime) {
            let start = (leave.selectedStartTime).split(':')
            let end = (leave.selectedEndTime).split(':')
            let break_minutes = leave.break_minutes
            let startDate = new Date()
            let endDate = new Date()

            startDate.setHours(start[0], start[1])
            endDate.setHours(end[0], end[1])

            let timeDiffHours = new DateDiff(new Date(endDate), new Date(startDate)).hours()
            break_minutes > 0 ? timeDiffHours = (((timeDiffHours * 60) - (break_minutes * 1)) / 60) : '0'
            totalCalc = totalCalc + timeDiffHours

            console.log(totalCalc)

          }
          if (this.trackLeaveOnDays) {
            console.log(this.totalRequestedTime)
            this.classBind = (this.totalRequestedTime / this.practiceWorkingHours) <= 0;

          } else {
            this.classBind = ((this.allocatedLeaveHours - this.usedLeaveHours) - this.totalRequestedTime) <= 0;
          }
        }
        this.totalRequestedTime = totalCalc
      } catch (error) {
        console.log(error)
      }
    },

    hoursCalculate (workingHours) {
      try {
        if (workingHours.selectedStartTime && workingHours.selectedEndTime) {
          let start = (workingHours.selectedStartTime).split(':')
          let end = (workingHours.selectedEndTime).split(':')
          let break_minutes = workingHours.break_minutes
          let startDate = new Date()
          let endDate = new Date()

          startDate.setHours(start[0], start[1])
          endDate.setHours(end[0], end[1])

          let timeDiffHours = new DateDiff(new Date(endDate), new Date(startDate)).hours()
          break_minutes > 0 ? timeDiffHours = (((timeDiffHours * 60) - (break_minutes * 1)) / 60) : '0'
          this.annualLeaveHours()
           return timeDiffHours.toFixed(1)
        }
      } catch (error) {
        console.log(error)
      }

    },
    async getLeaveReasons () {
      try {
        const leaves = await leaveManagementSalariedStaff.getLeaveReasons()
        this.leaveReasonOptions = leaves.data.data
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    saveDisable () {
      if (this.leaveReason && this.leaveRequests[0].selectedStartTime && this.leaveRequests[0].selectedEndTime && this.leaveRequests[0].selectedStartTime !== this.leaveRequests[0].selectedEndTime && this.leaveRequests[0].selectedStartTime < this.leaveRequests[0].selectedEndTime) {
        return !this.isActive
      } else {
        return this.isActive === true
      }
    },
    async getSicknessTypes (data) {
      try {
        if (data === 1) {
          this.formLoading = true
          const leaves = await leaveManagementSalariedStaff.getSicknessTypes()
          this.sicknessTypeOptions = leaves.data.data
          this.showSicknesInput = true
          this.formLoading = false
        } else {
          this.formLoading = true
          this.sicknessTypeOptions = null
          this.sicknessType = ''
          this.showSicknesInput = false
          this.formLoading = false
        }
      } catch (error) {
        this.formLoading = false
        this.convertAndNotifyError(error)
      }
    }
  },
  watch: {
    isRequestLeaveSidebarActive (val) {
      this.$refs.requestLeaveForm.reset()
      if (val) {
        this.getUserSummaryDetails(this.userDetails.id)
        this.getLeaveReasons()
      }
    },
    'selectedDayOffStartDate': function (value) {
      // this.selectedDayOffEndDate = null
      this.minDate = new Date(value)
      if (value) this.disableEndDate = false
      this.selectedDayOffEndDate = ''
    },
    'selectedDayOffEndDate': function (value) {
      this.getWorkingHours(this.userDetails.id, this.selectedDayOffStartDate, this.selectedDayOffEndDate)
      this.maxDate = new Date(value)
    },
    async leaveReason (data) {
      await this.getSicknessTypes(data)
    }
  },
  mounted () {
    this.practiceWorkingHours = this.$store.state.userWorkingHoursPerDay
    this.userDetails = this.$store.state.userDetails
    this.initTrHeight()
  },
  created () {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.initTrHeight)
  }

}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#request-leave-side-bar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }

}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }

}

.minus-value {
  color: #cc0000
}

.plus-value {
  color: #1B9AAA
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>

